<template>
  <div>
    <div class="air__utils__heading">
      <h5>Visualización de Vigilancia</h5>
    </div>
    <!--    Enlaces-->
    <my-breadcrumbs :routes="routes"/>
    <div class="row">
      <div class="col">
        <div class="mb-5">
          <div class="card">
            <div class="card-body text-center">

              <!--BARRA-->
              <services-table-bar @getData="getActiveData"
                :sortByOptions="tableObjectActive.sortByOptions"
                @searchValue="tableObjectActive.searchValue = $event"
                @sortByValue="tableObjectActive.sortByValue = $event"
                @sortDirectionValue="tableObjectActive.sortDirectionValue = $event"
                @perPageValue="tableObjectActive.perPageValue = $event"
                @currentPageValue="tableObjectActive.currentPageValue = $event"
              >
                <template slot="filters">
                  <div class="col-sm-12 offset-sm-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3" >
                    <a-form-item label="Servicio">
                      <a-select v-model="filterService" allowClear style="width: 100%" @change="getActiveData()"
                                placeholder="Todos los Servicios"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in Services" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                          CLAVE: {{option.jsonApi.id}} / SAP:{{option.sap_programming_code}}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6" >
                    <b-form-group
                      id="plant-group"
                      label="Planta"
                      label-for="plant"
                    >
                      <b-form-select v-model="filterWorkCenter" :options="WorkCenters"
                                     id="plant" label="Planta" @change="getActiveData()"
                                     value-field="jsonApi.id" text-field="name">
                        <template slot="first">
                          <b-form-select-option value="" >Todas las Plantas</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6" >
                    <b-form-group
                      id="client-group"
                      label="Cliente"
                      label-for="client"
                    >
                      <b-form-select v-model="filterClient" :options="Clients"
                                     id="client" label="Cliente" @change="getActiveData()"
                                     value-field="jsonApi.id" text-field="name">
                        <template slot="first">
                          <b-form-select-option value="" >Todos los Clientes</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </template>
              </services-table-bar>
              <!--TABLA-->
              <surveillance-table @Select="SelectForCheckList"
                :tableObject="tableObjectActive"
               @Filter="getActiveData"
                @searchInput="searchInput = $event"
                @selectedService="nextServiceFolio = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal :title="`CheckList de  ${servicePlanningCarrierID}`" ref="checklist-Form" size="xl" centered>

      <template v-if="showSurveillanceCheck">
        <b-alert show variant="warning" class="text-center text-capitalize">
          Este servico ya cuenta con un checkList registrado, consulte su reporte
        </b-alert>
        <div class="row justify-content-center my-4">
          <responsive-button text="Impresión de Reporte" icon="printer-fill"
                             pill variant="secondary" size="sm" responsive="md"
                             :to="{name:'check_list_report', params: {checkListFormId: checkListFormId}}"
          />
        </div>
      </template>
      <template v-else>

        <check-list v-if="service_planning_status !== 'Concluido'"
                    :checkLists="getCheckStructure(receptionState.surveillanceCheck)"
                    @updateCheckListForm="surveillanceCheckListForm = $event"
                    @totalFields="surveillanceTotalFields = $event">
          <b-container fluid slot="form">
            <a-form-model layout="horizontal" :model="surveillanceForm" :rules="surveillanceRules" ref="surveillance-Form">
              <a-row align="middle" justify="center">
                <a-col :sm="{ span: 24, }" :md="{ span:12}" >
                  <a-form-model-item label="Nombre del Vigilante" class="mx-1" ref="inspector_user_id" prop="inspector_user_id">
                    <a-select v-model="surveillanceForm.inspector_user_id"
                              placeholder="Seleccionar"
                              :disabled="infoView"
                              show-search
                              :filter-option="filterOption"
                    >
                      <a-select-option  v-for="user in surveillanceInspectors" :key="user.jsonApi.id" :value="user.jsonApi.id" >
                        {{user.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :sm="{ span: 24, }" :md="{ span:6}" ref="date" prop="date">
                  <a-form-model-item label="Fecha de Inspección" class="mx-1">
                    <a-date-picker format="DD/MM/YYYY" v-model="surveillanceForm.date" />
                  </a-form-model-item>
                </a-col>
                <a-col :sm="{ span: 24, }" :md="{ span:6}">
                  <a-form-model-item label="Hora Final de Inspección" class="mx-1"  ref="final_hour" prop="final_hour">
                    <a-time-picker v-model="surveillanceForm.final_hour" format="HH:mm" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <my-spinner :load="surveillanceLoad"/>
            <div class="row justify-content-center my-4" v-show="!surveillanceLoad">
              <div class="col-2">
                <responsive-button  variant="success" pill text="Guardar" @ClickEvent="saveCheckListForm"
                                    responsive="md"  icon="archive-fill" size="sm"/>
              </div>
            </div>
          </b-container>
        </check-list>
        <div v-else>
          <b-alert show variant="warning" class="text-center text-capitalize">
            Este servico ha sido concluido sin haber relizado el check list
          </b-alert>
        </div>
      </template>

      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseCheckListModal" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import surveillanceTable from '@/views/geocycle/service_plannings/components/surveillanceTable'
import servicesTableBar from '@/views/geocycle/service_plannings/components/servicesTableBar'
import checkList from '@/views/geocycle/service_plannings/components/checkList'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import api from '@/services/axios'

export default {
  name: 'vigilanceTable',
  mixins: [getGeneralMixin, apiRestMixin],
  components: {
    surveillanceTable,
    servicesTableBar,
    checkList,
  },
  computed: {
    ...mapState(['servicePlannings']),
    ...mapGetters('receptionServicePlannings', ['receptionState', 'getServiceID', 'getCheckStructure']),
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
  },

  data() {
    return {
      // *********************************************************
      // Variables para la funcionalidad del formulario de filtros
      WorkCenters: [],
      filterWorkCenter: '',
      Clients: [],
      filterClient: '',
      Services: [],
      filterService: undefined,
      // Esto de abajo creo se borra
      form: {},
      rules: {},
      // Variables para la funcionalidad del formulario de filtros
      // *********************************************************
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Vigilancia',
          aIcon: '',
        },
      ],
      resource: 'servicePlannings',
      resourceName: 'Servicios',
      searchInput: null,
      // Constants
      // <!-- Table -->
      // <!-- Services -->
      tableObjectActive: {
        data: [],
        // data: services,
        // header: 'Tabla de Servicios',
        columns: [
          {
            title: 'No. Programación SAP',
            dataIndex: 'sap_programming_code',
            key: 'sap_programming_code',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Planta',
            dataIndex: '',
            key: 'work_centers',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'workCenter',
            },
          },
          // {
          //   title: 'Cliente',
          //   dataIndex: 'clientContracts',
          //   key: 'client',
          //   align: 'center',
          //   width: 'auto',
          //   class: 'small',
          //   scopedSlots: {
          //     customRender: 'client',
          //   },
          // },
          {
            title: 'Transporte',
            dataIndex: 'servicePlanningsCarriers',
            key: 'transport',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'transport',
            },
          },
          {
            title: 'Corrientes',
            dataIndex: 'servicePlanningsMaterials',
            key: 'currents',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'currents',
            },
          },
          {
            title: 'Fecha y Hora de Programación',
            dataIndex: '',
            key: 'program_date',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'program_date',
            },
          },
          {
            title: 'Tipo de Servicio',
            dataIndex: 'serviceTypes',
            key: 'service',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'service',
            },
          },
          {
            title: 'Etapa del Servicio',
            dataIndex: 'lastSubstage',
            key: 'stage',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'stage',
            },
          },
          {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'status',
            },
          },
          {
            title: 'Estatus de SAP',
            dataIndex: 'sap_status',
            key: 'sap_status',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'sap_status',
            },
          },
          {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            // fixed: 'right',
            class: 'small',
            align: 'center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        // SearchBar
        searchValue: '',
        sortByValue: '',
        sortByDefaultValue: '-code',
        sortDirectionValue: '',
        sortByOptions: [
          { value: '', text: 'Ordenar por', disabled: true },
          { value: 'code', text: 'Folio' },
        ],
        perPageValue: 15,
        currentPageValue: 1,
      },
      // <!-- Table -->
      // -----  Check List  -----//
      // Vigilancia
      folio: '',
      surveillanceInspectors: [],
      surveillanceCheckListForm: {},
      surveillanceTotalFields: 0,
      serviceDetailModalShow: false,
      serviceDetailModalService: {},
      surveillanceForm: {
        inspector_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      },
      surveillanceRules: {
        inspector_user_id: [
          { required: true, message: 'El Nombre del inspector obligatorio.', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'La Fecha de Inspección es obligatoria.', trigger: 'blur' },
        ],
        final_hour: [
          { required: true, message: 'El Hora de Inspección es obligatoria.', trigger: 'blur' },
        ],
      },
      surveillanceLoad: false,
      servicePlanningCarrierID: '',
      surveillanceChecks: {},
      showSurveillanceCheck: true,
      checkListFormId: '0',
      infoView: false,
      // Control de estatus concluido para checkList
      service_planning_status: '',
      // Control de estatus concluido para checkList
    }
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    // Métodos para la carga y funcionalidad de los filtros
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // Extracción de Información de los combos
    async GetFilters() {
      this.Clients = await this.getSelectData('clients', { sort: 'name' })
      this.WorkCenters = await this.getSelectData('workCenters', { sort: 'name' })
      this.Services = await this.getSelectData('servicePlannings', {})
    },
    // Extracción de Información de los combos
    // <!-- Get,Paginate,Search & Filter Data  -->
    sortHelper(tableObject) {
      let direction, value
      if (tableObject.sortDirectionValue === '') {
        direction = ''
      } else if (tableObject.sortDirectionValue === '-') {
        direction = '-'
      } else {
        direction = ''
      }

      if (tableObject.sortByValue === '') {
        value = tableObject.sortByDefaultValue
      } else {
        value = tableObject.sortByValue
      }
      return direction + value
    },
    async getActiveData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObjectActive.pagination.current = paginationEvent.current
        this.tableObjectActive.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObjectActive.pagination.current}` : `${this.tableObjectActive.currentPageValue}`,
        'page[size]': `${this.tableObjectActive.perPageValue}`,
        sort: this.sortHelper(this.tableObjectActive),
        include: 'servicePlannings,weightRegistries,drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies',
      }
      if (this.filterClient !== '') {
        params['filter[clients]'] = this.filterClient
      }
      if (this.filterService !== '') {
        params['filter[service]'] = this.filterService
      }
      if (this.filterWorkCenter !== '') {
        params['filter[workCenters]'] = this.filterWorkCenter
      }
      if (this.tableObjectActive.searchValue !== '') {
        params['filter[tag]'] = this.tableObjectActive.searchValue
      }

      this.tableObjectActive.loading = true
      await this.$store.dispatch('jv/get', ['servicePlanningsCarriers', {
        params: params,
      }])
        .then(response => {
          this.tableObjectActive.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObjectActive.data = objectArray(tableData)
          this.tableObjectActive.pagination = resolvePagination(jsonApiPagination)
        })
        .catch(() => {
          this.tableObjectActive.loading = false
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Open & Close Modal Logic  -->
    // Se agrega check de vigilancia
    ...mapActions('receptionServicePlannings', ['SET_CHECKSTRUCTURE', 'SET_SERVICEID']),
    async SelectForCheckList(data) {
      this.service_planning_status = data.servicePlannings.service_planning_status
      this.folio = data.servicePlannings.jsonApi.id
      // Set the servicePlanningCarrierID
      this.$refs['checklist-Form'].show()
      this.servicePlanningCarrierID = data.jsonApi.id
      await this.GET_CHECK(this.receptionState.surveillanceCheck)
      this.showSurveillanceCheck = false
      // Verificar si existe el checkListForm correspondiente al servicePlanningCarrierID
      const params = {
        'filter[servicePlannings]': this.folio,
        'filter[servicePlanningsCarriers]': this.servicePlanningCarrierID,
        'filter[checks]': this.receptionState.surveillanceCheck,
      }

      this.$store.dispatch('jv/get', ['checkListForms', {
        params: params,
      }])
        .then(response => {
          if (response.jsonApi.json.data.length > 0) {
            const keys = Object.keys(response)
            const checkListForm = response[keys[0]]
            this.checkListFormId = checkListForm.jsonApi.id
          }
          this.showSurveillanceCheck = response.jsonApi.json.data.length > 0
        })
    },
    CloseCheckListModal() {
      this.showSurveillanceCheck = false
      this.$refs['checklist-Form'].hide()
      this.folio = ''
      this.servicePlanningCarrierID = ''
      this.surveillanceForm = {
        inspector_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      }
    },
    saveCheckListForm() {
      this.$refs['surveillance-Form'].validate(valid => {
        if (valid) {
          // Validar el # de campos contestados
          if (this.surveillanceTotalFields !== Object.keys(this.surveillanceCheckListForm).length) {
            Modal.error({
              title: '¡Ha ocurrido un error!',
              content: 'Debe contestar todos los campos del Check List',
              zIndex: 3000,
            })
            return
          }
          // Create Request
          const request = {
            check_id: this.receptionState.surveillanceCheck,
            service_planning_id: this.folio,
            service_plannings_carrier_id: this.servicePlanningCarrierID,
            inspectors: this.surveillanceForm.inspector_user_id,
            date: this.surveillanceForm.date,
            final_hour: moment(this.surveillanceForm.final_hour).format('HH:mm'),
            comments: this.surveillanceForm.comments,
            answers: this.surveillanceCheckListForm,
          }
          this.surveillanceLoad = true
          api.post('/saveCheckListAnswers', request)
            .then(response => {
              this.CloseCheckListModal()
              this.getActiveData()
              this.surveillanceLoad = false
              Modal.success({
                title: '¡Actualizado!',
                content: 'Se ha registrado el CheckList exitosamente',
                zIndex: 3000,
              })
            })
            .catch(() => {
              this.surveillanceLoad = false
            })
        } else {
          return false
        }
      })
    },
    async getSurveillanceInspectors() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'users')
      this.surveillanceInspectors = this.generalResponse
      this.generalResponse = []
    },
    // Se agrega check de vigilancia
  },
  async mounted() {
    await this.getActiveData()
    await this.GetFilters()
    await this.getSurveillanceInspectors()
  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
