<template>
  <div>
    <a-table
      :scroll="{ x: 1300, y: 1000}"
      :dataSource="tableObject.data"
      :columns="carrierColumns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="refreshResults"
      @expand="expand"
      bordered
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
        <template v-else>{{text}}</template>
      </template>
      <!-- Columnas Personalizadas -->
      <template slot="title">
        <h5 class="text-block text-default mt-2 mb-4 ml-4 text-center">
          {{ tableObject.header }}
        </h5>
      </template>
      <template slot="sap_programming_code" slot-scope="servicePlannings">
        {{servicePlannings.sap_programming_code}}
      </template>
      <div slot="all" slot-scope="data">
        <h6 class="text-secondary text-center">
          <strong>Placa: </strong>
          <span class="text-uppercase">{{data.trucks.tag}}</span> &nbsp;
          <b-icon v-if="!data.excessPermanence" icon="check-circle-fill" variant="primary" />
          <b-icon v-else icon="exclamation-triangle-fill" variant="warning" />
        </h6>

        <template v-if="data.trucks.carrierCompanies.sct_record">
          <strong>Registro SCT: </strong>{{data.trucks.carrierCompanies.sct_record}}
          <br>
        </template>
        <template v-if="data.trucks.carrierCompanies.environmental_record">
          <strong>Autorización SEMARNAT: </strong>{{data.trucks.carrierCompanies.environmental_record}}
          <br>
        </template>
        <strong>Tipo Logístico de Transporte: </strong> {{data.trucks.logisticsShipmentTypes.name}}
        <br>
        <strong>Acoplados: </strong>{{data.trucks.logisticsShipmentTypes.trailers}}
        <br>
        <strong>Conductor: </strong>{{data.drivers.name}}
        <br>
      </div>
      <div slot="coupled_tag" slot-scope="coupled_tag">
        <ol>
          <li v-for="tag in coupled_tag" :key="tag">{{tag}}</li>
        </ol>
      </div>
      <div slot="in" slot-scope="data">
        <h6 class="text-secondary"><strong>Ticket: </strong>{{data.weightRegistries.entry_ticket}}</h6>
        <h6 class="text-secondary"><strong>Peso: </strong>{{data.weightRegistries.entry_weight}}</h6>
        <h6 class="text-secondary"><strong>Fecha: </strong>{{data.weightRegistries.entry_dated_at}}</h6>
        <h6 class="text-secondary"><strong>Hora: </strong>{{data.weightRegistries.entry_hour}}</h6>
      </div>
      <div slot="output" slot-scope="data">
        <h6 class="text-secondary"><strong>Ticket: </strong>{{data.weightRegistries.exit_ticket}}</h6>
        <h6 class="text-secondary"><strong>Peso: </strong>{{data.weightRegistries.exit_weight}}</h6>
        <h6 class="text-secondary"><strong>Fecha: </strong>{{data.weightRegistries.exit_dated_at}}</h6>
        <h6 class="text-secondary"><strong>Hora: </strong>{{data.weightRegistries.exit_hour}}</h6>
      </div>
      <div slot="checkList" slot-scope="data">
        <template v-if="data.jsonApi.meta.checkCodes.length > 0">
          <span v-show="data.jsonApi.meta.checkCodes.includes('Check_0000000001')">
            Vigilancia <b-icon icon="check-circle" variant="primary" font-scale="1.5"/>
          </span>
        </template>
        <span v-else class="bg-warning py-2 px-2 rounded text-white font-weight-bold">
          No se ha realizado ningún Check List
        </span>
      </div>
      <a slot="action" slot-scope="data">
        <b-button-group>
          <responsive-button pill text="Pesaje" icon="calculator"
                             class="mb-1 bg-real-blue"  responsive="md" size="sm"
                             :to="{name:'reception_service_plannings_weighing',params:{carrierID:data.jsonApi.id}}"/>

          <responsive-button text="Check List" icon="hand-index-thumb" class="mb-1"
                             pill variant="warning" size="sm" responsive="md"
                             @ClickEvent="Select(data)"
          />

        </b-button-group>
      </a>
      <template slot="footer">
        <b-icon icon="check-circle-fill" variant="primary" /> <span>: Sin Exceso de Permanencia</span>
        <br>
        <b-icon icon="exclamation-triangle-fill" variant="warning" /> <span>: Con Exceso de Permanencia</span>
      </template>
    </a-table>

  </div>
</template>

<script>
export default {
  name: 'surveillanceTable',
  props: {
    tableObject: Object,
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      // Columnas fijas de la tabla
      carrierColumns: [
        {
          title: 'No. Programación SAP',
          dataIndex: 'servicePlannings',
          key: 'sap_programming_code',
          align: 'center',
          width: '10%',
          class: 'small',
          scopedSlots: {
            customRender: 'sap_programming_code',
          },
        },
        {
          title: 'Descripción',
          dataIndex: '',
          key: 'all',
          class: 'small',
          width: '20%',
          scopedSlots: {
            customRender: 'all',
          },
        },
        {
          title: 'Placas de Acoplados',
          dataIndex: 'coupled_tag',
          key: 'coupled_tag',
          class: 'small',
          width: '10%',
          scopedSlots: {
            customRender: 'coupled_tag',
          },
        },
        {
          title: 'Pesajes',
          width: '30%',
          align: 'center',
          children: [
            {
              title: 'Entrada',
              key: 'in',
              align: 'center',
              width: 'auto',
              class: 'small',
              scopedSlots: {
                customRender: 'in',
              },
            },
            {
              title: 'Salida',
              dataIndex: '',
              key: 'output',
              align: 'center',
              width: 'auto',
              class: 'small',
              scopedSlots: {
                customRender: 'output',
              },
            },
          ],
        },
        {
          title: 'CheckList',
          dataIndex: '',
          align: 'center',
          class: 'small',
          width: '15%',
          scopedSlots: {
            customRender: 'checkList',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          key: 'x',
          class: 'small',
          align: 'center',
          width: '15%',
          scopedSlots: {
            customRender: 'action',
          },
        }],
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    Select(data) {
      this.$emit('Select', data)
    },
    expand(expanded, record) {
      console.log(record)
    },
    GoToServicePlanning(data) {
      this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: data.sap_programming_code })
      this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: data.jsonApi.id })
      this.$router.push({ name: 'service', params: { folio: data.sap_programming_code, id: data.jsonApi.id } })
    },
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
